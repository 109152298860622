
/*--
   07. Product Details Css
---------------------------*/
.modal-dialog {
  max-width: 1024px;
}
.modal-wrapper .modal-body {
  padding: 30px 15px;
}
.modal-wrapper button.close {
  font-size: 24px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 5px;
}
.modal-wrapper .modal {
  display: inline-block !important;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: -9;
}
.modal-wrapper .modal.show {
  visibility: visible;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 99950;
}
.modal-wrapper {
    & .modal-dialog {
        max-width: 1000px;
        @media #{$tablet-device}{
            width: 680px;
        }
        @media #{$large-mobile}{
            width: 470px;
        }
        @media #{$small-mobile}{
            width: 300px;
        }
    }
}
.portfolio-full-image {
    border: 1px solid #ddd;
    & img {
      width: 100%;
    } 
}
.pot-small-img {
    border: 1px solid #ddd;
   & img {
      width: 100%;
    } 
}
.product_big_images-right {
    float: right;
    width: calc(100% - 130px);
    padding-left: 15px;
    @media #{$tablet-device}{
        width: calc(100% - 0px);
        padding-left: 0px;
        margin-bottom: 10px;
    }
    @media #{$large-mobile}{
        width: calc(100% - 0px);
        padding-left: 0px;
        margin-bottom: 10px;
    }
    @media #{$small-mobile}{
        width: calc(100% - 0px);
        padding-left: 0px;
        margin-bottom: 10px;
    }
}
.product_small_images-left {
    float: left;
    width: 115px;
    @media #{$tablet-device}{
        width: 100%;
    }
    @media #{$large-mobile}{
        width: 100%;
    }
    @media #{$small-mobile}{
        width: 100%;
    }
}
.vartical-product-active {
    li {
        margin: 0 0px 10px 0;
        border: 1px solid #ddd !important;
        &:last-child {
            margin-bottom: 0;
        }
        
        @media #{$tablet-device}{
            margin: 0 10px 0px 0;
        }
        @media #{$large-mobile}{
            margin: 0 10px 0px 0;
        }
        @media #{$small-mobile}{
            margin: 0 10px 0px 0;
        }
        button {
            background-color: transparent;
            border: none;
        }
    }
    @media #{$tablet-device}{
        .slick-list {
          margin: 0 -9px 0 0;
        }
    }
    @media #{$large-mobile}{
        .slick-list {
          margin: 0 -9px 0 0;
        }
    }
    @media #{$small-mobile}{
        .slick-list {
          margin: 0 -9px 0 0;
        }
    }
}
.product_small_images-left,
.product_small_images-bottom {
    & .arrow-prv,
    & .arrow-next {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ddd;
        color: #fff;
        z-index: 9;
        position: absolute;
        top: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: $theme-color;
        }
    }
    & .arrow-next {
        right: 0;
    }
    &:hover {
        & .arrow-prv,
        & .arrow-next {
            opacity: 1;
            visibility: visible;
        }
    }
} 
.product_details_info {
    @media #{$small-mobile , $large-mobile}{
        margin-top: 20px;
    }
}

.horizantal-product-active {
    margin-top: 15px;
    margin-left: 1px;
    margin-right: 1px;
    & .pot-small-img {
        margin-right: 15px;
    }
    button {
        border: none;
        background-color: transparent;
    }
}
.product_small_images-bottom {
    & .slick-list {
      margin-right: -15px;
    } 
}

.product_details_info {
    & > h2 {
        color: #444444;
        font-size: 30px;
        font-weight: 500;
        text-transform: capitalize;
    }
}
.product-rating {
    & li {
        padding-right: 10px;
        color: $theme-color;
        font-size: 18px;
    }
}
.pro_details {
    & p {
        margin-top: 10px;
    }
}
.pro_dtl_prize {
    & li {
        font-size: 22px;
        color: $theme-color;
        display: inline-block;
        margin-right: 5px;
        margin-top: 10px;
        font-weight: 500;
        &.old_prize {
            font-size: 20px;
            color: $black;
            text-decoration: line-through;
        }
    }
}
.title_2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
}
.pro_dtl_color {
    margin-top: 15px;
    & li {
        font-size: 18px;
        display: inline-block;
        padding-right: 10px;
        &:last-child {
            padding: 0;
        }
    }
    & .red  a {
        color: #ff5d42;
    }
    & .blue  a {
        color: #425dff;
    }
    & .perpal a {
        color: #fd42ff;
    }
    & .yellow a {
        color: #ffd542;
    }
}
.pro_dtl_size {
    margin-top: 15px;
    & .pro_choose_size {
       & li {
            display: inline-block;
            padding-right: 10px;
            &:last-child {
                padding: 0;
            }
        }
    }
}
.product-quantity-action {
    margin-top: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    & .product-quantity {
        margin-left: 10px;
        & input {
            width: 40px;
            text-align: center;
            height: 30px;
        }
    }
}
.pro_social_share {
    margin-top: 15px;
    & .pro_social_link {
        & > li {
            display: inline-block;
            padding-left: 10px;
        }
    }
}
.pro_dtl_btn {
    margin-top: 20px;
    display: flex;
    & li {
        margin-right: 10px;
        & a {
            border: 1px solid #dddddd;
            display: block;
            font-size: 20px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 40px;
            &.buy_now_btn {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                width: 120px;
            }
            &:hover {
                background: $theme-color;
                color: $white;
                border: 1px solid $theme-color;
            }
        }
    }
}

.product-details-tab {
    & .nav {
        justify-content: center;
        border-bottom: 1px solid #ddd;
        & li {
            margin-right: 15px;
            & button {
                font-weight: 500;
                font-size: 18px;
                display: block;
                padding-bottom: 20px;
                @include transition(.4s);
                position: relative;
                background-color: transparent;
                border: none;
                &::before {
                    content: "";
                    background: $theme-color;
                    width: 0%;
                    height: 2px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include opacity(0);
                    @include transition(.4s);
                }
                &.active {
                    color: $theme-color;
                    &::before{
                       visibility: visible;
                       @include opacity(1);
                       width: 100%;
                    }
                }
                @media #{$small-mobile}{
                  padding-bottom: 10px;
                    &:last-child {
                        margin-top: 10px;
                    }
                }
            }
            &:hover{
                & > a {
                    &::before{
                       visibility: visible;
                       @include opacity(1);
                       width: 100%;
                    }
                }
            }
        }
    }
}
.single-product-gallery {
  float: left;
  padding: 5px;
  width: 50%;
}

.title_3 {
  font-size: 22px;
  font-weight: 500;
}

.feature_list {
    & li {
        margin-bottom: 5px;
        &:last-child {
            margin: 0;
        }
        & a {
            & i {
                font-size: 18px;
                color: $black;
                margin-right: 8px;
            }
        }
    }
}
.pro_review {
    display: flex;
    &.ans {
        margin-bottom: 50px;
        margin-left: 50px;
        margin-top: 50px;
        @media #{$small-mobile}{
          margin-bottom: 30px;
          margin-left: 20px;
          margin-top: 30px;
        }
    }
}
